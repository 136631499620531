$(document).ready(function ($) {
  $(window).scroll(function () {
    var now = $(window).scrollTop();
    var under = $('body').height() - (now + $(window).height());
    if (now > 100) {
      $('#page-top').fadeIn('slow');
    } else {
      $('#page-top').fadeOut('slow');
    }
  });
  $('#move-page-top').click(function () {
    $('html,body').animate({
      scrollTop: 0
    }, 'slow');
  });

  $('a[href^="#"]').click(function () {
    var speed = 500;
    var href = $(this).attr("href");
    var target = $(href == "#" || href == "" ? 'html' : href);
    var position = target.offset().top;
    $("html, body").animate({
      scrollTop: position
    }, speed, "swing");
    return false;
  });

  $(window).scroll(function () {
    if ($(this).scrollTop() > 100) {
      $("#header").addClass('view_bg');
      $(".navbar-nav").addClass('b-txt');
      $(".insta-icon").addClass('b-txt');
      $(".book-icon").addClass('b-txt');
      // $(".logo-b").addClass('no-img');
      // $(".logo-w").addClass('view-img');
    } else {
      $("#header").removeClass('view_bg');
      $(".navbar-nav").removeClass('b-txt');
      $(".insta-icon").removeClass('b-txt');
      $(".book-icon").removeClass('b-txt');
      // $(".logo-b").removeClass('no-img');
      // $(".logo-w").removeClass('view-img');
    }
  });
});
